
import { defineComponent, onMounted } from 'vue'
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";

export default defineComponent({
  components: {
    // DarkModeSwitcher,
  },
  setup () {
    onMounted(() => {
      cash('body')
        .removeClass('app')
        .removeClass('error-page')
        .addClass('login')
    })
  }
})
